/* istanbul ignore file */
import {
  Context, Logger,
  useUserFactory,
  UseUserFactoryParams as UserUserFactoryParamsBase,
} from '@vue-storefront/core';
import { CustomerCreateInput, UpdateCustomerEmailMutationVariables } from '@vue-storefront/gemini-api';
import { CustomQuery } from '@vue-storefront/core/lib/src/types';
import useCart from '../useCart';
import { generateUserData } from '../../helpers/userDataGenerator';

interface UseUserFactoryParams<USER, UPDATE_USER_PARAMS, REGISTER_USER_PARAMS>
  extends UserUserFactoryParamsBase<USER, UPDATE_USER_PARAMS, REGISTER_USER_PARAMS> {
  logIn: (context: Context, params: {
    username: string;
    password: string;
    recaptchaToken?: string;
    customQuery?: CustomQuery;
  }) => Promise<USER>;

  register: (context: Context, params: REGISTER_USER_PARAMS & {
    customQuery?: CustomQuery;
    recaptchaInstance?: any;
  }) => Promise<USER>;
}

const factoryParams: UseUserFactoryParams<
any,
UpdateCustomerEmailMutationVariables,
CustomerCreateInput
> = {
  provide() {
    return {
      cart: useCart(),
    };
  },
  load: async (context: Context) => {
    Logger.debug('[Gemini] Load user information');
    const apiState = context.$gemini.config.state;

    if (!apiState.getCustomerToken()) {
      return null;
    }
    try {
      const { data } = await context.$gemini.api.customer();

      Logger.debug('[Result]:', { data });
      apiState.setSegments(
        apiState.getCustomerToken(),
        data.customer?.segments,
      );

      return data?.customer ?? {};
    } catch {
      // eslint-disable-next-line no-void
      // @ts-ignore
      await factoryParams.logOut(context);
    }

    return null;
  },
  logOut: async (context: Context, params) => {
    const apiState = context.$gemini.config.state;

    await context.$gemini.api.revokeCustomerToken(params);

    apiState.setCustomerToken(null);
    apiState.setCartId(null);
    apiState.setWishlist(null);
    apiState.setSegments(null);
    context.cart.setCart(null);
  },
  updateUser: async (context: Context, params) => {
    Logger.debug('[Gemini] Update user information', { params });

    const { email: oldEmail } = params.currentUser;
    const { email, password, ...updateData } = params.updatedUserData;

    const userData = generateUserData(updateData);

    if (email && email !== oldEmail) {
      Logger.debug('[Gemini] Update user email', { params });
      // @ts-ignore
      const { errors: updateEmailErrors } = await context.$gemini.api.updateCustomerEmail({
        email,
        password,
      });

      if (updateEmailErrors) {
        Logger.error(updateEmailErrors);

        throw new Error(updateEmailErrors.map((e) => e.message).join(','));
      }

      Logger.debug('[Gemini] Authenticate user');
      const apiState = context.$gemini.config.state;

      const { data, errors: authErrors } = await context.$gemini.api.generateCustomerToken(
        {
          email,
          password,
          recaptchaToken: '',
        },
      );

      Logger.debug('[Result]:', { data });

      if (authErrors) {
        Logger.error(authErrors);

        throw new Error(authErrors.map((e) => e.message).join(','));
      }
      if (!data.generateCustomerToken || !data.generateCustomerToken.token) {
        throw new Error('Customer sign-in error');
      }
      apiState.setCustomerToken(data.generateCustomerToken.token);
    }

    const { data, errors: updateErrors } = await context.$gemini.api.updateCustomer(userData);

    if (updateErrors) {
      Logger.error(updateErrors);

      throw new Error(updateErrors.map((e) => e.message).join(','));
    }

    Logger.debug('[Result]:', { data });

    // return data.updateCustomerV2.customer;
    return data?.updateCustomerV2?.customer || {};
  },
  register: async (context: Context, params) => {
    const {
      email,
      password,
      ...baseData
    } = generateUserData(params);

    const { data, errors } = await context.$gemini.api.createCustomer(
      {
        email,
        password,
        ...baseData,
      },
    );

    Logger.debug('[Result]:', { data });

    if (errors) {
      Logger.error(errors);

      throw new Error(errors.map((e) => e.message).join(','));
    }
    if (!data.createCustomerV2 || !data.createCustomerV2.customer) {
      throw new Error('Customer registration error');
    }
    return factoryParams.logIn(context, { username: email, password });
  },
  logIn: async (context: Context, params) => {
    Logger.debug('[Gemini] Authenticate user');
    const apiState = context.$gemini.config.state;

    const { data, errors } = await context.$gemini.api.generateCustomerToken(
      {
        email: params.username,
        password: params.password,
        recaptchaToken: params.recaptchaToken,
      },
    );

    Logger.debug('[Result]:', { data });

    if (errors) {
      Logger.error(errors);

      throw new Error(errors.map((e) => e.message).join(','));
    }
    if (!data.generateCustomerToken || !data.generateCustomerToken.token) {
      throw new Error('Customer sign-in error');
    }
    apiState.setCustomerToken(data.generateCustomerToken.token);

    apiState.setWishlist(null);

    // merge existing cart with customer cart
    const currentCartId = apiState.getCartId();
    const cart = await context.$gemini.api.customerCart();
    const newCartId = cart.data.customerCart.id;

    if (newCartId && currentCartId && currentCartId !== newCartId) {
      const { data: dataMergeCart, errors: errorsMergeCart } = await context.$gemini.api.mergeCarts(
        {
          sourceCartId: currentCartId,
          destinationCartId: newCartId,
        },
      );

      if (errorsMergeCart) {
        Logger.error('MergeCart errors: ', errorsMergeCart);

        throw new Error(errorsMergeCart.map((e) => e.message).join(','));
      }

      context.cart.setCart(dataMergeCart.mergeCarts);

      apiState.setCartId(dataMergeCart.mergeCarts.id);
    } else {
      context.cart.setCart(cart.data.customerCart);
    }

    return factoryParams.load(context);
  },
  changePassword: async (context: Context, params) => {
    Logger.debug('[Gemini] changing user password');
    const { data, errors } = await context.$gemini.api.changeCustomerPassword(params);

    if (errors) {
      Logger.error(errors);

      throw new Error(errors.map((e) => e.message).join(','));
    }

    Logger.debug('[Result] ', { data });

    return data?.changeCustomerPassword;
  },
};

export default useUserFactory<
any,
UpdateCustomerEmailMutationVariables,
CustomerCreateInput & { email: string; password: string, recaptchaToken?: string }
>(factoryParams);
