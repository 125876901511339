import { AgnosticBreadcrumb, AgnosticCategoryTree, CategoryGetters } from '@vue-storefront/core';
import { Category, FilterEqualTypeInput } from '@vue-storefront/gemini-api';
import { Scalars } from '@vue-storefront/gemini-api/lib/types/GraphQL';
import { htmlDecode } from '../helpers/htmlDecoder';

export const getEntityId = (category: Category): string =>
  // @ts-ignore
  // eslint-disable-next-line implicit-arrow-linebreak
  category?.entityId || (typeof category?.uid === 'string' && category?.uid?.split('::')[1]) || '';

export const getSlug = (category: Category): string => {
  let slug = getEntityId(category) !== '' ? `/c/${getEntityId(category)}` : '';

  // @ts-ignore
  const rewrites = category?.urls;

  // @ts-ignore
  if (category?.slug && category.slug !== '') {
    // @ts-ignore
    slug = category.slug;
  } else if (rewrites?.length > 0) {
    // find canonical url
    const thisRewrite = rewrites.find((rewrite) => rewrite?.linkRel === 'Canonical');
    if (thisRewrite?.urlPath && thisRewrite.urlPath !== '') {
      slug = thisRewrite.urlPath;
    }
  }

  return slug !== '' ? `/${slug.replace(/^(\/)/, '')}` : '';
};

const buildCategoryTree = (rootCategory: any, currentCategory: string, withProducts = false): AgnosticCategoryTree => {
  const hasChildren = Array.isArray(rootCategory.children) && rootCategory.children.length > 0;
  const isCurrent = rootCategory.uid === currentCategory;
  const label = htmlDecode(rootCategory.name);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const slug = getSlug(rootCategory) ?? '';

  const childrenUid = hasChildren ? rootCategory.children.reduce((acc, curr) => [...acc, curr.uid], []) : [];

  const childProductCount = hasChildren
    ? rootCategory.children
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      .reduce((acc, curr) => acc + curr.product_count, 0)
    : 0;

  const items = hasChildren
    ? rootCategory.children.filter((c) => (withProducts ? c.product_count > 0 : true)).map((c) => buildCategoryTree(c, currentCategory))
    : [];

  return {
    label,
    slug,
    uid: [rootCategory.uid, ...childrenUid],
    items: items.filter((c) => c.count > 0),
    count: childProductCount || rootCategory.product_count,
    isCurrent,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getTree = (category: Category): AgnosticCategoryTree | null => {
  if (!category) {
    return null;
  }
  return buildCategoryTree(category, '');
};

export const getCategoryTree = (category: Category, currentCategory: string = '', withProducts = false): AgnosticCategoryTree | null => (category ? buildCategoryTree(category, currentCategory, withProducts) : null);

export const getCategoryBreadcrumbs = (category: any): AgnosticBreadcrumb[] => {
  let breadcrumbs = [];

  if (!category) {
    return [];
  }

  if (Array.isArray(category?.breadcrumbs)) {
    breadcrumbs = category.breadcrumbs.map(
      (breadcrumb) => ({
        text: breadcrumb.category_name,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        link: getSlug(breadcrumb),
      } as AgnosticBreadcrumb),
    );
  }

  breadcrumbs.push({
    text: category.name,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    link: getSlug(category),
  } as AgnosticBreadcrumb);

  return breadcrumbs;
};

export const getCategoryUidFilter = (category_uid: Scalars['ID']): FilterEqualTypeInput => ({
  eq: category_uid,
});

const categoryGetters: CategoryGetters<Category> = {
  getTree,
  getBreadcrumbs: getCategoryBreadcrumbs,
  getCategoryTree,
  getEntityId,
  getSlug,
  getCategoryUidFilter,
};

export default categoryGetters;
