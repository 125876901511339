import { Ref, computed } from '@vue/composition-api';
import { PlatformApi } from '@vue-storefront/core/lib/src/types';
import {
  ComposableFunctionArgs, configureFactoryParams, Context, FactoryParams, Logger, sharedRef,
} from '@vue-storefront/core';
import { UseMenu, UseMenuErrors } from '../types/composables';

export interface UseMenuFactoryParams<MENU, API extends PlatformApi = any> extends FactoryParams<API> {
  search: (context: Context, params: ComposableFunctionArgs<{ id?: string; code?: string }>) => Promise<MENU>;
}

export function useMenuFactory<MENU, API extends PlatformApi = any>(
  factoryParams: UseMenuFactoryParams<MENU, API>,
) {
  return function useMenu(ssrKey = 'useMenuFactory'): UseMenu<MENU, API> {
    // @ts-ignore
    const menu: Ref<MENU> = sharedRef<MENU>({}, `${ssrKey}-menu`);
    const loading = sharedRef<boolean>(false, `${ssrKey}-menu-loading`);
    const error: Ref<UseMenuErrors> = sharedRef({ search: null }, `${ssrKey}-menu-error`);

    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
    const _factoryParams = configureFactoryParams(factoryParams);

    const search = async (params: ComposableFunctionArgs<{ id?: string; code?: string }>): Promise<void> => {
      Logger.debug(`useMenu/${ssrKey}/search`);

      try {
        loading.value = true;
        menu.value = await _factoryParams.search(params);
        error.value.search = null;
      } catch (err) {
        error.value.search = err;
        Logger.error(`useMenu/${ssrKey}/search`, err);
      } finally {
        loading.value = false;
      }
    };

    return {
      api: _factoryParams.api,
      search,
      loading: computed(() => loading.value),
      menu: computed(() => menu.value),
      error: computed(() => error.value),
    };
  };
}
