import { Menu } from '@vue-storefront/gemini-api';
import { Context, Logger } from '@vue-storefront/core';
import { MenuFilter } from '@vue-storefront/gemini-api/lib/types/GraphQL';
import { useMenuFactory, UseMenuFactoryParams } from '../../factories/useMenuFactory';

const factoryParams: UseMenuFactoryParams<Menu> = {
  search: async (context: Context, params) => {
    Logger.debug('[Gemini]: Load Menu', { params });

    const filter: MenuFilter = {};
    if (params.id) filter.uid = params.id;
    if (params.code) filter.code = params.code;

    const { data, errors } = await context.$gemini.api.menu(filter);

    if (errors) {
      errors.forEach((error) => {
        Logger.error(`${error.extensions.code} - ${error.message}`);
      });
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw errors;
    }

    Logger.debug('[Gemini]: Menu result', data);

    return data.menu;
  },
};

export default useMenuFactory<Menu>(factoryParams);
