import {
  Context, Logger,
} from '@vue-storefront/core';
import { Countries, Country } from '@vue-storefront/gemini-api';
import { UseCountryFactoryParams, useCountrySearchFactory } from '../../factories/useCountrySearchFactory';
import { UseCountrySearch } from '../../types/composables';

const factoryParams: UseCountryFactoryParams<Countries, Country> = {
  load: async (context: Context, _params): Promise<Countries[]> => {
    Logger.debug('[Gemini]: Load available countries on store');

    const { data } = await context.$gemini.api.countries();

    Logger.debug('[Result]:', { data });

    return data.countries;
  },
  search: async (context: Context, params): Promise<Country> => {
    Logger.debug('[Gemini]: Search country information based on', { params });

    const { data } = await context.$gemini.api.country(params.id);

    Logger.debug('[Result]:', { data });

    return data.country;
  },
};

const useCountrySearch: (cacheId?: string) => UseCountrySearch<Countries, Country> = useCountrySearchFactory<Countries, Country>(factoryParams);

export default useCountrySearch;
