import {
  useCategoryFactory,
  Context,
  UseCategoryFactoryParams, Logger,
} from '@vue-storefront/core';
import {
  Category, CategoryListQueryVariables,
} from '@vue-storefront/gemini-api';

const factoryParams: UseCategoryFactoryParams<Category, CategoryListQueryVariables> = {
  // @ts-ignore
  categorySearch: async (context: Context, params) => {
    Logger.debug('[Gemini]: List available categories', { params });

    const { data } = await context.$gemini.api.categoryList(params);

    Logger.debug('[Result]:', { data });

    return data.categories.items;
  },
};

export default useCategoryFactory<Category, CategoryListQueryVariables>(factoryParams);
