/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/require-await */
/* istanbul ignore file */
import { Context, Logger } from '@vue-storefront/core';
import { Product, Wishlist } from '@vue-storefront/gemini-api';
import useUser from '../useUser';
import { findItemOnWishlist } from '../../helpers/findItemOnWishlist';
import { useWishlistFactory, UseWishlistFactoryParams } from '../../factories/useWishlistFactory';

const getProductWishlistMap = async (context, params) => {
  const { product } = params;
  if (!product) {
    Logger.debug('[Gemini Storefront]: useWishlist.getProductWishlistMap no product provided', params);
    return {};
  }

  Logger.debug('[Gemini Storefront]: useWishlist.getProductWishlistMap params->', params);
  const apiState = context.$gemini.config.state;

  const wishlistUid = apiState.getWishlist();
  if (!wishlistUid) {
    return null;
  }

  const grnsToCheck = [...(product?.variants?.map((variant) => variant.product?.uid) ?? []), product.uid];
  const { data } = await context.$gemini.api.itemsInWishlist({
    wishlistUid,
    items: grnsToCheck,
  });

  const selectedProductGrn = product.variantGrn || product.configurable_product_options_selection?.variant?.sku || product.uid;
  const wishlistItemDetails = data?.itemsInWishlist?.find((p) => p.product_id === selectedProductGrn)?.wishlist_ids;
  const selectedProductWishlistItem = wishlistItemDetails?.find((w) => w.wishlist_uid.split('::')[1] === wishlistUid);
  const mappedProduct = {};
  if (selectedProductWishlistItem) {
    mappedProduct[selectedProductWishlistItem.wishlist_uid] = selectedProductWishlistItem.item_uid;
  }
  return mappedProduct;
};

const factoryParams: UseWishlistFactoryParams<Wishlist, Product> = {
  provide() {
    return {
      user: useUser(),
    };
  },
  // @ts-ignore
  load: async (context: Context, params) => {
    Logger.debug('[Gemini Storefront]: useWishlist.load params->', params);
    const apiState = context.$gemini.config.state;

    let wishlistUid = apiState.getWishlist();
    if (apiState.getCustomerToken()) {
      const queryParams = {
        wishlistUid,
        itemsPageSize: params?.pageSize,
        itemsNextPageToken: params?.nextPageToken,
      };
      // TODO add error management
      const { data } = await context.$gemini.api.customerWishlist(queryParams);
      wishlistUid = data?.customer?.wishlist?.uid;
      apiState.setWishlist(wishlistUid);
      return data?.customer?.wishlist ?? {};
    }
    if (wishlistUid) {
      const queryParams = {
        wishlistUid,
        itemsPageSize: params?.pageSize,
        itemsNextPageToken: params?.nextPageToken,
      };

      const { data } = await context.$gemini.api.wishlist(queryParams);
      // TODO add error management
      return data?.wishlist ?? {};
    }
    return {};
  },
  // @ts-ignore
  addItem: async (context, params) => {
    const { product, quantity } = params;
    Logger.debug('[Gemini Storefront]: useWishlist.addItem params->', params);
    const apiState = context.$gemini.config.state;

    // TODO add check if is customer and if has a wishlist

    let wishlistUid = apiState.getWishlist();
    if (!wishlistUid) {
      const { data } = await context.$gemini.api.createWishlist();
      wishlistUid = data?.createWishlist?.uid;
      apiState.setWishlist(wishlistUid);
    }

    const variantGrn = product.configurable_product_options_selection?.variant?.sku;

    const { data } = await context.$gemini.api.addItemToWishlist({
      wishlistUid,
      item: {
        sku: variantGrn ?? product.uid,
        quantity: quantity ?? 1,
      },
    });
    Logger.debug('[Result]:', { data });

    // @ts-ignore
    return data?.addItemToWishlist ?? {};
  },
  // @ts-ignore
  updateItem: async (context, params) => {
    const { product, quantity } = params;
    Logger.debug('[Gemini Storefront]: useWishlist.updateItem params->', params);

    const apiState = context.$gemini.config.state;

    const wishlistUid = apiState.getWishlist();
    if (!wishlistUid) {
      return;
    }

    const wishlistsItemMap = await getProductWishlistMap(context, { product });
    const itemGrn = wishlistsItemMap[`grn:wishlist:wishlist::${wishlistUid}`];
    if (!itemGrn) {
      return;
    }

    const itemId = itemGrn.split('::')?.[1]?.split('/')[1];
    if (!itemId) {
      return;
    }

    const { data } = await context.$gemini.api.updateItemInWishlist({
      wishlistUid,
      wishlistItem: {
        wishlist_item_uid: itemId,
        quantity,
      },
    });

    Logger.debug('[Result] useWishlist.updateItem:', { data });

    // eslint-disable-next-line consistent-return
    return data?.updateItemInWishlist ?? {};
  },
  // @ts-ignore
  removeItem: async (context, params) => {
    const { product } = params;
    Logger.debug('[Gemini Storefront]: useWishlist.removeItem params->', params);

    const apiState = context.$gemini.config.state;

    const wishlistUid = apiState.getWishlist();
    if (!wishlistUid) {
      return;
    }

    const wishlistsItemMap = await getProductWishlistMap(context, { product });
    const itemGrn = wishlistsItemMap[`grn:wishlist:wishlist::${wishlistUid}`];
    if (!itemGrn) {
      return;
    }

    const itemId = itemGrn.split('::')?.[1]?.split('/')[1];
    if (!itemId) {
      return;
    }

    const { data } = await context.$gemini.api.removeItemFromWishlist({
      wishlistUid,
      wishlistItemUid: itemId,
    });

    Logger.debug('[Result] useWishlist.removeItem:', { data });

    // eslint-disable-next-line consistent-return
    return data?.removeItemFromWishlist ?? {};
  },
  clear: async ({ currentWishlist }) => ({}),
  isInWishlist: (context, params) => {
    const { currentWishlist, product } = params;
    const wishlistProduct = findItemOnWishlist(currentWishlist, product);
    return !!(wishlistProduct?.id && wishlistProduct?.quantity);
  },
  hasItemsInWishlist: async (context, params) => {
    const { items } = params;
    Logger.debug('[Gemini Storefront]: useWishlist.hasItemsInWishlist params->', params);
    const apiState = context.$gemini.config.state;

    const wishlistUid = apiState.getWishlist();
    if (!wishlistUid) {
      return Object.fromEntries(items.map((item) => [item, false]));
    }

    const { data } = await context.$gemini.api.itemsInWishlist({
      wishlistUid,
      items,
    });

    const inWishlist = data?.itemsInWishlist.map((item) => item.product_id) ?? [];
    // eslint-disable-next-line unicorn/prefer-object-from-entries
    return items.reduce((prev, item) => {
      // we are interested only in the parent id to check if it is in the wishlist
      const parentId = item?.split('/')?.[0];
      if (!prev[parentId]) {
        // eslint-disable-next-line no-param-reassign
        prev[parentId] = inWishlist.includes(item);
      }
      return prev;
    }, {});
  },
  getProductWishlistMap,
};

export default useWishlistFactory<Wishlist, Product>(factoryParams);
