export const getIdentifier = (item: any): string => item.identifier || '';
export const getContent = (item: any): string => item.content || '';
export const getMetaTitle = (item: any): string => item.meta_title || '';
export const getMetaDescription = (item: any): string => item.meta_description || '';
export const getContentHeading = (item: any): string => item.content_heading || '';
export const getTitle = (item: any): string => item.title || '';

// cms
// eslint-disable-next-line unicorn/prefer-object-from-entries
export const getCmsBlockContent = (items: any): any => items.reduce((prev, item) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, no-param-reassign
  prev[item.identifier] = JSON.parse(item.content);
  return prev;
}, {});

const contentGetters = {
  getIdentifier,
  getContent,
  getMetaTitle,
  getMetaDescription,
  getContentHeading,
  getTitle,
  getCmsBlockContent,
};

export default contentGetters;
