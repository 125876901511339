import {
  Context,
  Logger,
} from '@vue-storefront/core';
import {
  PaymentMethodInput,
  SetPaymentMethodOnCartInputs,
} from '@vue-storefront/gemini-api';
import useCart from '../useCart';
import { usePaymentProviderFactory, UsePaymentProviderParams } from '../../factories/usePaymentProviderFactory';

const factoryParams: UsePaymentProviderParams<any, PaymentMethodInput> = {
  provide() {
    return {
      cart: useCart(),
    };
  },
  load: async (context: Context, { customQuery }) => {
    Logger.debug('[Gemini] loadPaymentProvider', { customQuery });
    const cartId = context.cart.cart.value.id;
    const { data } = await context
      .$gemini
      .api
      .getAvailablePaymentMethods({ cartId });

    Logger.debug('[Result]:', { data });

    // if there is at least one virtual product, skip no upfront payment methods
    // for example: skip "cod" if there is a Gift Card in the cart
    let cartContainsVirtualProducts = false;
    context.cart.cart.value.items.forEach((item) => {
      if (item.product.is_virtual) {
        cartContainsVirtualProducts = true;
        Logger.debug('[Gemini] virtual product found in the cart, skip no upfront payment methods');
      }
    });

    if (cartContainsVirtualProducts && Object.prototype.hasOwnProperty.call(data.cart, 'available_payment_methods')) {
      data.cart.available_payment_methods.forEach((method, index) => {
        if (method.is_upfront === false) {
          delete data.cart.available_payment_methods[index];
          Logger.debug('[Gemini] removed available payment method because virtual product:', method.code);
        }
      });
    }

    return data
      .cart
      .available_payment_methods;
  },

  save: async (context: Context, params) => {
    Logger.debug('[Gemini] savePaymentProvider', { params });

    const paymentMethodParams: SetPaymentMethodOnCartInputs = {
      cart_id: context.cart.cart.value.id,
      payment_method: {
        ...params.paymentMethod,
      },
    };

    const { data } = await context
      .$gemini
      .api
      .setPaymentMethodOnCart(paymentMethodParams);

    Logger.debug('[Result]:', { data });

    const { cart } = data
      .setPaymentMethodOnCart;

    context.cart.setCart(cart);

    return data
      .setPaymentMethodOnCart
      .cart
      .available_payment_methods;
  },
};

export default usePaymentProviderFactory<any, PaymentMethodInput>(factoryParams);
