/* istanbul ignore file */
import { Context, Logger } from '@vue-storefront/core';
import useUser from '../useUser';
import { useNewsletterFactory, UseNewsletterFactoryParams } from '../../factories/useNewsletterFactory';

const factoryParams: UseNewsletterFactoryParams<any, string> = {
  provide() {
    return {
      user: useUser(),
    };
  },
  updateSubscription: async (context: Context, params) => {
    Logger.debug('[Gemini]: Update user newsletter subscription', { params });

    const { data, errors } = await context.$gemini.api.subscribeEmailToNewsletter({
      email: params.email,
      name: params.name,
      lastName: params.lastName,
    });

    if (errors && errors.length > 0) {
      const err = new Error(errors[0].message);
      err.name = `${errors[0].extensions?.code}`;
      throw err;
    }

    Logger.debug('[Result]:', { data });

    return data.subscribeEmailToNewsletter?.status;
  },
};

export default useNewsletterFactory<any, string>(factoryParams);
