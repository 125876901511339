import {
  UseCart, Context, FactoryParams, PlatformApi, sharedRef, Logger, configureFactoryParams, ComposableFunctionArgs,
} from '@vue-storefront/core';
import { computed, Ref } from '@vue/composition-api';
import { CustomQuery } from '@vue-storefront/core/lib/src/types';

export interface UseCartErrors {
  addItem: Error;
  removeItem: Error;
  updateItemQty: Error;
  load: Error;
  clear: Error;
  applyCoupon: Error;
  removeCoupon: Error;
  applyGiftCard: Error;
  removeGiftCard: Error;
  addNoteOnCart: Error;
  addAdditionalInfoOnCart: Error;
}

export interface UseCartGemini<CART, CART_ITEM, PRODUCT, API extends PlatformApi = any> extends UseCart<CART, CART_ITEM, PRODUCT, API> {
  applyGiftCard(params: {
    giftCardCode: string;
    customQuery?: CustomQuery;
  }): Promise<void>;
  removeGiftCard(params: {
    giftCardCode: string;
    customQuery?: CustomQuery;
  }): Promise<void>;
  addNoteOnCart(params: {
    note: string;
    customQuery?: CustomQuery;
  }): Promise<void>;
  addAdditionalInfoOnCart(params: {
    productId: string;
    message: string;
    email: string;
    customQuery?: CustomQuery;
  }): Promise<void>;
}

export interface UseCartFactoryParams<CART, CART_ITEM, PRODUCT, API extends PlatformApi = any> extends FactoryParams<API> {
  load: (context: Context, params: ComposableFunctionArgs<{ realCart?: boolean; }>) => Promise<CART>;
  addItem: (
    context: Context,
    params: ComposableFunctionArgs<{
      currentCart: CART;
      product: PRODUCT;
      quantity: any;
    }>
  ) => Promise<CART>;
  removeItem: (context: Context, params: ComposableFunctionArgs<{ currentCart: CART; product: CART_ITEM; }>) => Promise<CART>;
  updateItemQty: (
    context: Context,
    params: ComposableFunctionArgs<{ currentCart: CART; product: CART_ITEM; quantity: number; }>
  ) => Promise<CART>;
  clear: (context: Context, params: { currentCart: CART }) => Promise<CART>;
  applyCoupon: (context: Context, params: ComposableFunctionArgs<{ currentCart: CART; couponCode: string; }>) => Promise<{ updatedCart: CART }>;
  applyGiftCard: (context: Context, params: ComposableFunctionArgs<{ currentCart: CART; giftCardCode: string; }>) => Promise<{ updatedCart: CART }>;
  removeCoupon: (
    context: Context,
    params: ComposableFunctionArgs<{ currentCart: CART; couponCode: string; }>
  ) => Promise<{ updatedCart: CART }>;
  removeGiftCard: (
    context: Context,
    params: ComposableFunctionArgs<{ currentCart: CART; giftCardCode: string; }>
  ) => Promise<{ updatedCart: CART }>;
  isInCart: (context: Context, params: { currentCart: CART; product: PRODUCT }) => boolean;
  addNoteOnCart: (
    context: Context,
    params: ComposableFunctionArgs<{ currentCart: CART; note: string; }>
  ) => Promise<{ updatedCart: CART }>;
  addAdditionalInfoOnCart: (
    context: Context,
    params: ComposableFunctionArgs<{ currentCart: CART; productId: string; message: string; email: string }>
  ) => Promise<{ updatedCart: CART }>;
}

export const useCartFactory = <CART, CART_ITEM, PRODUCT, API extends PlatformApi = any>(
  factoryParams: UseCartFactoryParams<CART, CART_ITEM, PRODUCT, API>,
) => function useCart(): UseCartGemini<CART, CART_ITEM, PRODUCT, API> {
  const loading: Ref<boolean> = sharedRef(false, 'useCart-loading');
  const cart: Ref<CART> = sharedRef(null, 'useCart-cart');
  const error: Ref<UseCartErrors> = sharedRef({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    applyGiftCard: null,
    removeGiftCard: null,
    addNoteOnCart: null,
    addAdditionalInfoOnCart: null,
  }, 'useCart-error');

  // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/naming-convention
  const _factoryParams = configureFactoryParams(
    factoryParams,
    {
      mainRef: cart,
      alias: 'currentCart',
      loading,
      error,
    },
  );

  const setCart = (newCart: CART) => {
    cart.value = newCart;
    Logger.debug('useCartFactory.setCart', newCart);
  };

  const restrictProduct = ({
    product,
    getExpectedQuantity,
    updatedCart,
    action,
  }) => {
    const sku = product?.sku || '';

    const getItemQty = (cartValue): number => cartValue?.items
      ?.find((i) => i.product.sku === sku)
      ?.quantity ?? 0;

    const expectedQty = getExpectedQuantity(getItemQty(cart.value));
    const actualQty = getItemQty(updatedCart);

    if (actualQty && expectedQty > actualQty) {
      const err = new Error('You reached the maximum saleable quantity');
      Logger.debug('[Restricted]:', `Max saleable quantity is ${actualQty}`);
      error.value[action] = err;
    } else {
      error.value[action] = null;
    }

    cart.value = updatedCart;
  };

  const addItem = async ({
    product,
    quantity,
    customQuery,
  }) => {
    Logger.debug('useCart.addItem', {
      product,
      quantity,
    });

    try {
      loading.value = true;
      const updatedCart = await _factoryParams.addItem({
        currentCart: cart.value,
        product,
        quantity,
        customQuery,
      });

      restrictProduct({
        product: product?.configurable_product_options_selection?.variant ?? product,
        getExpectedQuantity(currentQuantity: number) {
          return currentQuantity + (quantity as number);
        },
        updatedCart,
        action: 'addItem',
      });
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useCart/addItem', err);
    } finally {
      loading.value = false;
    }
  };

  const removeItem = async ({
    product,
    customQuery,
  }) => {
    Logger.debug('useCart.removeItem', { product });

    try {
      loading.value = true;
      const updatedCart = await _factoryParams.removeItem({
        currentCart: cart.value,
        product,
        customQuery,
      });
      error.value.removeItem = null;
      cart.value = updatedCart;
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useCart/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const updateItemQty = async ({
    product,
    quantity,
    customQuery,
  }) => {
    Logger.debug('useCart.updateItemQty', {
      product,
      quantity,
    });

    if (quantity && quantity > 0) {
      try {
        loading.value = true;
        const updatedCart = await _factoryParams.updateItemQty({
          currentCart: cart.value,
          product,
          quantity,
          customQuery,
        });

        restrictProduct({
          product: product.product,
          getExpectedQuantity() {
            return quantity;
          },
          updatedCart,
          action: 'updateItemQty',
        });
      } catch (err) {
        error.value.updateItemQty = err;
        Logger.error('useCart/updateItemQty', err);
      } finally {
        loading.value = false;
      }
    }
  };

  const load = async ({ customQuery } = { customQuery: undefined }) => {
    Logger.debug('useCart.load', cart.value);

    try {
      loading.value = true;
      cart.value = await _factoryParams.load({ customQuery });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCart/load', err);
    } finally {
      loading.value = false;
    }
  };

  const clear = async () => {
    Logger.debug('useCart.clear');

    try {
      loading.value = true;
      const updatedCart = await _factoryParams.clear({ currentCart: cart.value });
      error.value.clear = null;
      cart.value = updatedCart;
    } catch (err) {
      error.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const isInCart = ({ product }) => _factoryParams.isInCart({
    currentCart: cart.value,
    product,
  });

  const applyCoupon = async ({
    couponCode,
    customQuery,
  }) => {
    Logger.debug('useCart.applyCoupon');

    try {
      loading.value = true;
      const { updatedCart } = await _factoryParams.applyCoupon({
        currentCart: cart.value,
        couponCode,
        customQuery,
      });
      error.value.applyCoupon = null;
      if (updatedCart) {
        cart.value = updatedCart;
      }
    } catch (err) {
      error.value.applyCoupon = err;
      Logger.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({
    couponCode,
    customQuery,
  }) => {
    Logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      const { updatedCart } = await _factoryParams.removeCoupon({
        currentCart: cart.value,
        couponCode,
        customQuery,
      });
      error.value.removeCoupon = null;
      if (updatedCart) {
        cart.value = updatedCart;
      }
      loading.value = false;
    } catch (err) {
      error.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const applyGiftCard = async ({
    giftCardCode,
    customQuery,
  }) => {
    Logger.debug('useCart.applyGiftCard');

    try {
      loading.value = true;
      const { updatedCart } = await _factoryParams.applyGiftCard({
        currentCart: cart.value,
        giftCardCode,
        customQuery,
      });
      error.value.applyGiftCard = null;
      if (updatedCart) {
        cart.value = updatedCart;
      }
    } catch (err) {
      error.value.applyGiftCard = err;
      Logger.error('useCart/applyGiftCard', err);
    } finally {
      loading.value = false;
    }
  };

  const removeGiftCard = async ({
    giftCardCode,
    customQuery,
  }) => {
    Logger.debug('useCart.removeGiftCard');

    try {
      loading.value = true;
      const { updatedCart } = await _factoryParams.removeGiftCard({
        currentCart: cart.value,
        giftCardCode,
        customQuery,
      });
      error.value.removeGiftCard = null;
      if (updatedCart) {
        cart.value = updatedCart;
      }
      loading.value = false;
    } catch (err) {
      error.value.removeGiftCard = err;
      Logger.error('useCart/removeGiftCard', err);
    } finally {
      loading.value = false;
    }
  };

  const addNoteOnCart = async ({
    note,
    customQuery,
  }) => {
    Logger.debug('useCart.addNoteOnCart');

    try {
      loading.value = true;
      const { updatedCart } = await _factoryParams.addNoteOnCart({
        currentCart: cart.value,
        note,
        customQuery,
      });
      error.value.addNoteOnCart = null;
      if (updatedCart) {
        cart.value = updatedCart;
      }
      loading.value = false;
    } catch (err) {
      error.value.addNoteOnCart = err;
      Logger.error('useCart/addNoteOnCart', err);
    } finally {
      loading.value = false;
    }
  };

  const addAdditionalInfoOnCart = async ({
    productId,
    message,
    email,
    customQuery,
  }) => {
    Logger.debug('useCart.addAdditionalInfoOnCart');

    try {
      loading.value = true;
      const { updatedCart } = await _factoryParams.addAdditionalInfoOnCart({
        currentCart: cart.value,
        productId,
        message,
        email,
        customQuery,
      });
      error.value.addAdditionalInfoOnCart = null;
      if (updatedCart) {
        cart.value = updatedCart;
      }
      loading.value = false;
    } catch (err) {
      error.value.addAdditionalInfoOnCart = err;
      Logger.error('useCart/addAdditionalInfoOnCart', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    api: _factoryParams.api,
    setCart,
    cart: computed(() => cart.value),
    isInCart,
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    applyGiftCard,
    removeGiftCard,
    addNoteOnCart,
    addAdditionalInfoOnCart,
    loading: computed(() => loading.value),
    error: computed(() => error.value),
  };
};
