import {
  Context, Logger,
  useForgotPasswordFactory,
} from '@vue-storefront/core';
import { UseForgotPasswordFactoryParams } from '../../factories/useForgotPasswordFactory';

const factoryParams: UseForgotPasswordFactoryParams<any> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resetPassword: async (context: Context, params) => {
    Logger.debug('[Gemini]: Reset user password', { params });

    const { data } = await context.$gemini.api.requestPasswordResetEmail({ email: params.email });

    Logger.debug('[Result]:', { data });

    if (!data.requestPasswordResetEmail) throw new Error('Email was not found, or not available.');

    return data;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setNewPassword: async (context: Context, params) => {
    Logger.debug('[Gemini]: Define new user password', { params });

    const { data } = await context.$gemini.api.resetPassword({
      newPassword: params.newPassword,
      resetPasswordToken: params.tokenValue,
    });

    if (data === undefined) {
      throw new Error('Email was not found, not available or token is expired. Please request a new password.');
    }

    Logger.debug('[Result]:', { data });

    return data;
  },
};

export const useForgotPassword = useForgotPasswordFactory<any>(factoryParams);
export default useForgotPassword;
