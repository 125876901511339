/* istanbul ignore file */
/* eslint-disable no-param-reassign */
import {
  ComposableFunctionArgs,
  Context,
  Logger,
} from '@vue-storefront/core';
import {
  AddConfigurableProductsToCartInput,
  AddProductsToCartInput,
  Cart,
  CartItem,
  Product,
  RemoveItemFromCartInput,
  UpdateCartItemsInput,
} from '@vue-storefront/gemini-api';
import {
  UseCartFactoryParams,
  useCartFactory,
} from '../../factories/useCartFactory';

// TODO: use error codes from backend
function isUnrecoverableError(err: Error): boolean {
  const unrecoverableErrors: string[] = [
    'cart not found',
    'not active cart',
    'not a guest cart',
    'not customer cart',
  ];

  if (err?.message) {
    return !!unrecoverableErrors.find((e) => err.message.toLowerCase().includes(e.toLowerCase()));
  }

  return false;
}

const factoryParams: UseCartFactoryParams<Cart, CartItem, Product> = {
  load: async (context: Context, params: ComposableFunctionArgs<{
    realCart?: boolean;
  }>) => {
    const apiState = context.$gemini.config.state;
    Logger.debug('[Gemini Storefront]: Loading Cart');

    const customerToken = apiState.getCustomerToken();
    const virtual = !params.realCart;

    const createVirtualCart = () => (null as Cart);

    const createRealCart = async (): Promise<string> => {
      Logger.debug('[Gemini Storefront]: useCart.load.createNewCart');

      apiState.setCartId();

      const { data } = await context.$gemini.api.createEmptyCart();
      Logger.debug('[Result]:', { data });

      apiState.setCartId(data.createEmptyCart);

      return data.createEmptyCart;
    };

    const getCartData = async (id: string) => {
      Logger.debug('[Gemini Storefront]: useCart.load.getCartData ID->', id);

      const { data, errors } = await context.$gemini.api.cart(id);
      Logger.debug('[Result]:', { data });

      if (!data?.cart && errors?.length) {
        throw errors[0];
      }

      return data.cart as unknown as Cart;
    };

    const getCart = async (virtualCart: boolean, cartId?: string) => {
      if (!cartId) {
        if (virtualCart) {
          return createVirtualCart();
        }

        cartId = await createRealCart();
        apiState.setCartId(cartId);
      }

      return getCartData(cartId);
    };

    // Try to load cart for existing customer, clean customer token if not possible
    if (customerToken) {
      try {
        const { data, errors } = await context.$gemini.api.customerCart();
        Logger.debug('[Result]:', { data, errors });

        if (!data?.customerCart && errors?.length) {
          throw errors[0];
        }

        apiState.setCartId(data.customerCart.id);

        return data.customerCart as unknown as Cart;
      } catch {
        // FIXME: disabled, it was too aggressive
        // apiState.setCustomerToken();
        // apiState.setSegments();
      }
    }

    try {
      // If it's not existing customer check if cart id is set and try to load it
      const cartId = apiState.getCartId();
      return await getCart(virtual, cartId);
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (isUnrecoverableError(err)) {
        console.error('Unrecoverable error:', err);
        apiState.setCartId();
        return await getCart(virtual);
      }

      console.error('Recoverable error:', err);
      throw err;
    }
  },

  addItem: async (context: Context, {
    product,
    quantity,
    currentCart,
  }) => {
    Logger.debug('[Gemini]: Add item to cart', {
      product,
      quantity,
      currentCart,
    });

    const apiState = context.$gemini.config.state;
    let currentCartId = apiState.getCartId();
    if (!currentCartId) {
      await factoryParams.load(context, {
        realCart: true,
      });

      currentCartId = apiState.getCartId();
    }

    if (!product) {
      return;
    }
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    switch (product.vsf_typename) {
      case 'SimpleProduct':
        const simpleCartInput: AddProductsToCartInput = {
          cartId: currentCartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
            },
          ],
        };

        const simpleProduct = await context.$gemini.api.addProductsToCart(simpleCartInput);

        Logger.debug('[Result]:', { data: simpleProduct });

        if (simpleProduct?.errors?.length > 0) {
          throw simpleProduct.errors[0];
        }

        // eslint-disable-next-line consistent-return
        return simpleProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart;
      case 'ConfigurableProduct':
        const cartItems = [
          {
            parent_sku: product.sku,
            data: {
              quantity,
              sku: product.configurable_product_options_selection?.variant?.sku || '',
            },
          },
        ];

        const configurableCartInput: AddConfigurableProductsToCartInput = {
          cart_id: currentCartId,
          cart_items: cartItems,
        };

        const configurableProduct = await context.$gemini.api.addConfigurableProductsToCart(configurableCartInput);

        Logger.debug('[Result]:', { data: configurableProduct });

        if (configurableProduct?.errors?.length > 0) {
          throw configurableProduct.errors[0];
        }

        // eslint-disable-next-line consistent-return
        return configurableProduct
          .data
          .addConfigurableProductsToCart
          .cart as unknown as Cart;
      default:
        // todo implement other options
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        throw new Error(`Product Type ${product.__typename} not supported in add to cart yet`);
    }
  },
  removeItem: async (context: Context, {
    currentCart,
    product,
  }) => {
    Logger.debug('[Gemini]: Remove item from cart', {
      product,
      currentCart,
    });

    const item = currentCart.items.find((cartItem) => cartItem.uid === product.uid);

    if (!item) {
      return;
    }

    const removeItemParams: RemoveItemFromCartInput = {
      cart_id: currentCart.id,
      cart_item_uid: item.uid,
    };

    const { data } = await context.$gemini.api.removeItemFromCart(removeItemParams);

    Logger.debug('[Result]:', { data });

    // eslint-disable-next-line consistent-return
    return data
      .removeItemFromCart
      .cart as unknown as Cart;
  },

  updateItemQty: async (context: Context, {
    currentCart,
    product,
    quantity,
  }) => {
    Logger.debug('[Gemini]: Update product quantity on cart', {
      product,
      quantity,
      currentCart,
    });

    const updateCartParams: UpdateCartItemsInput = {
      cart_id: currentCart.id,
      cart_items: [
        {
          cart_item_uid: product.uid,
          quantity,
        },
      ],
    };

    try {
      const { data } = await context.$gemini.api.updateCartItems(updateCartParams);

      Logger.debug('[Result]:', { data });

      return data
        .updateCartItems
        .cart as unknown as Cart;
    } catch {
      // If we can't change quantity, the card could be expired on Gemini side, try to reload
      return await factoryParams.load(context, {
        realCart: true,
      });
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clear: (context: Context, _params = null) => {
    context.$gemini.config.state.setCartId(null);

    return factoryParams.load(context, {});
  },
  applyCoupon: async (context: Context, {
    currentCart,
    couponCode,
  }) => {
    Logger.debug('[Gemini]: Apply coupon on cart', {
      couponCode,
      currentCart,
    });

    const { data, errors } = await context.$gemini.api.applyCouponToCart({
      cart_id: currentCart.id,
      coupon_code: couponCode,
    });

    if (errors?.length) {
      throw errors[0];
    }

    Logger.debug('[Result]:', { data });

    let cart: Cart;
    if (data.applyCouponToCart) {
      cart = data.applyCouponToCart.cart as unknown as Cart;
    }

    return {
      updatedCart: cart, // data.applyCouponToCart.cart as unknown as Cart as null,
      updatedCoupon: { code: couponCode },
      errors,
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeCoupon: async (context: Context, { currentCart }) => {
    Logger.debug('[Gemini]: Remove coupon from cart', { currentCart });

    const { data, errors } = await context.$gemini.api.removeCouponFromCart({
      cart_id: currentCart.id,
    });

    if (errors?.length) {
      throw errors[0];
    }

    Logger.debug('[Result]:', { data });

    return {
      updatedCart: data.removeCouponFromCart.cart as unknown as Cart as null,
      updatedCoupon: { code: '' },
    };
  },
  applyGiftCard: async (context: Context, {
    currentCart,
    giftCardCode,
  }) => {
    Logger.debug('[Gemini]: Apply coupon on cart', {
      giftCardCode,
      currentCart,
    });

    const { data } = await context.$gemini.api.applyGiftCardToCart({
      cart_id: currentCart.id,
      giftcard_code: giftCardCode,
    });

    Logger.debug('[Result]:', { data });

    return {
      updatedCart: data.applyGiftCardToCart.cart as unknown as Cart,
      updatedGiftCard: { code: giftCardCode },
    };
  },
  removeGiftCard: async (context: Context, { currentCart, giftCardCode }) => {
    Logger.debug('[Gemini]: Remove gift card from cart', { currentCart });

    const { data, errors } = await context.$gemini.api.removeGiftCardFromCart({
      cart_id: currentCart.id,
      giftcard_code: giftCardCode,
    });

    if (errors?.length) {
      throw errors[0];
    }

    Logger.debug('[Result]:', { data, errors });

    return {
      updatedCart: data.removeGiftCardFromCart.cart as unknown as Cart as null,
      updatedCoupon: { code: '' },
    };
  },

  addNoteOnCart: async (context: Context, { currentCart, note }) => {
    Logger.debug('[Gemini]: Add note to cart', { currentCart, note });

    const { data, errors } = await context.$gemini.api.setNotesToCart({
      cart_id: currentCart.id,
      notes: note,
    });

    if (errors?.length) {
      throw errors[0];
    }

    Logger.debug('[Result]:', { data, errors });

    return {
      updatedCart: data.cart as unknown as Cart as null,
      updatedCoupon: { code: '' },
    };
  },
  addAdditionalInfoOnCart: async (context: Context, {
    currentCart, productId, message, email,
  }) => {
    Logger.debug('[Gemini]: Add additional info to cart', {
      currentCart, productId, message, email,
    });
    const { data, errors } = await context.$gemini.api.setAdditionalInfoToCart({
      cart_grn: currentCart.id,
      product_id: productId,
      message,
      email,
    });

    if (errors?.length) {
      throw errors[0];
    }

    Logger.debug('[Result]:', { data, errors });

    return {
      updatedCart: data.cart as unknown as Cart as null,
      updatedCoupon: { code: '' },
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isInCart: (
    context: Context,
    {
      currentCart,
      product,
    },
  ) => !!currentCart?.items.find((cartItem) => cartItem?.product?.uid === product.uid),
};

export default useCartFactory<Cart, CartItem, Product>(factoryParams);
