import {
  Context, Logger, UseMakeOrder, useMakeOrderFactory, UseMakeOrderFactoryParams,
} from '@vue-storefront/core';
import { Order } from '@vue-storefront/gemini-api';
import useCart from '../useCart';

const factoryParams: UseMakeOrderFactoryParams<Order> = {
  provide() {
    return {
      cart: useCart(),
    };
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  make: async (context: Context, params): Promise<Order> => {
    Logger.debug('[Gemini] Make Order', { params });
    const { id } = context.cart.cart.value;
    const placeOrder = await context.$gemini.api.placeOrder({ cart_id: id });
    const { data, errors } = placeOrder;
    Logger.debug('[Result]:', { data });
    Logger.debug('[Errors]:', { errors });

    if (data?.placeOrder?.order) {
      return data.placeOrder?.order;
    }
    if (errors && errors.length > 0) {
      const err = new Error(errors[0].message);
      err.name = `${errors[0].extensions.code}`;
      throw err;
    } else if (placeOrder) {
      throw new Error(`${placeOrder}`);
    } else {
      throw new Error('Unable to finalize order');
    }
  },
};

const useMakeOrder: () => UseMakeOrder<Order> = useMakeOrderFactory<Order>(factoryParams);

export default useMakeOrder;
