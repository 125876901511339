import { SetGuestInfoOnCartInput } from '@vue-storefront/gemini-api';
import { Logger } from '@vue-storefront/core';
import { useGuestUserFactory, UseGuestUserFactoryParams } from '../../factories/useGuestUserFactory';
import useCart from '../useCart';

const factoryParams: UseGuestUserFactoryParams<any, SetGuestInfoOnCartInput> = {
  provide() {
    return {
      cart: useCart(),
    };
  },
  attachToCart: async (context, params) => {
    Logger.debug('[Gemini]: Attach guest cart to user');

    const infoOnCartInput: SetGuestInfoOnCartInput = {
      email: params.email,
      firstname: params.firstname,
      lastname: params.lastname,
      cart_id: context.cart.cart.value.id,
    };

    const { errors } = await context.$gemini.api.setGuestInfoOnCart({
      ...infoOnCartInput,
    });

    if (errors) {
      throw new Error((errors.map((e) => e.message).join(',')) as string);
    }
  },
};

export default useGuestUserFactory<any, SetGuestInfoOnCartInput & { email:string;password:string }>(factoryParams);
