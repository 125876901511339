import {
  Context,
  Logger,
  useShippingFactory,
  UseShippingParams,
} from '@vue-storefront/core';
import {
  SetShippingAddressesOnCartInput,
} from '@vue-storefront/gemini-api';
import useCart from '../useCart';
import useGetShippingMethods from '../useGetShippingMethods';

const factoryParams: UseShippingParams<any, any> = {
  provide() {
    return {
      useGetShippingMethods: useGetShippingMethods(),
      cart: useCart(),
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  load: async (context: Context, { customQuery }) => {
    Logger.debug('[Gemini] loadShipping', { customQuery });

    // workaround force to reload data
    // if (!context.cart.cart?.value?.shipping_addresses || true) {
    await context.cart.load({ customQuery });
    // }

    return context.cart.cart.value.shipping_addresses[0];
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  save: async (context: Context, params) => {
    Logger.debug('[Gemini] save user shipping address', { params });

    const { id } = context.cart.cart.value;

    const {
      apartment,
      neighborhood,
      extra,
      customerAddressId,
      ...address
    } = params.shippingDetails;

    const shippingData = customerAddressId
      ? ({
        customer_address_id: customerAddressId,
        address: {
          ...address,
          street: [address.street, apartment, neighborhood, extra],
        },
      })
      : ({
        address: {
          ...address,
          street: [address.street, apartment, neighborhood, extra],
        },
      });

    const shippingAddressInput: SetShippingAddressesOnCartInput = {
      cart_id: id,
      shipping_addresses: [
        {
          ...shippingData,
        },
      ],
    };

    const { data, errors } = await context
      .$gemini
      .api
      .setShippingAddressesOnCart(shippingAddressInput);

    if (errors && errors.length > 0) {
      throw errors[0];
    }

    Logger.debug('[Result]:', { data });

    context.useGetShippingMethods.setState(data
      .setShippingAddressesOnCart
      .cart
      .shipping_addresses[0]
      .available_shipping_methods);

    return data
      .setShippingAddressesOnCart
      .cart
      .shipping_addresses[0];
  },
};

export default useShippingFactory<any, any>(factoryParams);
