import {
  AgnosticAttribute,
  AgnosticBreadcrumb,
  AgnosticMediaGalleryItem,
  AgnosticPrice,
  ProductGetters as ProductGettersBase,
} from '@vue-storefront/core';
import { Category, Product, SwatchData } from '@vue-storefront/gemini-api';

import { ConfigurableProductOptionValue } from '@vue-storefront/gemini-api/lib/types/GraphQL';
import categoryGetters from './categoryGetters';
import { htmlDecode } from '../helpers/htmlDecoder';

type ProductVariantFilters = any;

export const getName = (product: Product): string => {
  if (!product) {
    return '';
  }

  return htmlDecode(product.name);
};

export const getEntityId = (product: Product): string => {
  const puid = product?.uid;
  return (typeof puid === 'string' && puid?.split('::')[1]) || '';
};

export const getSlug = (product: Product, _category?: Category): string => {
  let slug = getEntityId(product) !== '' ? `/p/${getEntityId(product)}` : '';

  // @ts-ignore
  const rewrites = product?.urls;

  if (rewrites?.length > 0) {
    // find canonical url
    const thisRewrite = rewrites.find((rewrite) => rewrite?.linkRel === 'Canonical');
    if (thisRewrite?.urlPath && thisRewrite.urlPath !== '') {
      slug = thisRewrite.urlPath;
    }
  }

  return slug !== '' ? `/${slug.replace(/^(\/)/, '')}` : '';
};

export const getPrice = (product: Product): AgnosticPrice => {
  let regular = 0;
  let special = null;

  let priceRange = product?.price_range;

  // Evaluate price range on selection if available
  if (product?.configurable_product_options_selection?.variant?.price_range) {
    priceRange = product.configurable_product_options_selection.variant.price_range;
  }

  if (priceRange && priceRange.minimum_price.regular_price.value == null) {
    priceRange = product.variant_price_range;
  }

  if (priceRange) {
    regular = priceRange.minimum_price.regular_price.value;
    const final = priceRange.minimum_price.final_price.value;

    if (final < regular) {
      special = final;
    }
  }

  return {
    regular,
    special,
  };
};

export const getFinalPrice = (product: Product): Number | null => {
  let priceRange = product.price_range;

  // Evaluate price range on selection if available
  if (product?.configurable_product_options_selection?.variant?.price_range) {
    priceRange = product.configurable_product_options_selection.variant.price_range;
  }

  if (priceRange && priceRange.minimum_price.regular_price.value == null) {
    priceRange = product.variant_price_range;
  }

  if (priceRange) {
    return priceRange.minimum_price.final_price.value;
  }
  return null;
};

export const getGallery = (product: Product): AgnosticMediaGalleryItem[] => {
  const images = [];

  if (!product?.media_gallery && !product?.configurable_product_options_selection?.media_gallery) {
    return images;
  }

  const selectedGallery = product.configurable_product_options_selection?.media_gallery.length > 0
    ? product.configurable_product_options_selection.media_gallery
    : product.media_gallery;

  // eslint-disable-next-line no-restricted-syntax
  for (const galleryItem of selectedGallery) {
    if (galleryItem && galleryItem.url) {
      images.push({
        small: galleryItem.url,
        normal: galleryItem.url,
        big: galleryItem.url,
        video_content: galleryItem?.video_content,
        label: galleryItem?.label ? galleryItem?.label : product.name,
      });
    }
  }

  return images;
};

export const getProductImageAlt = (product: Product, imageRole: string): string => {
  if (!product) {
    return '';
  }

  if (!product[imageRole]) {
    return product.name;
  }

  let imageAlt = product.name;
  if (product[imageRole].label !== '' && product[imageRole].label !== undefined && product[imageRole].label !== null) {
    imageAlt = product[imageRole].label;
  }

  return imageAlt;
};

export const getCoverImage = (product: Product): string => {
  if (!product || !product.image) {
    return null;
  }

  return product.image.url;
};

export const getRolloverImage = (product: Product, width?: number | null, format = 'a'): string => {
  if (!product || !product.rollover_image || !product?.rollover_image?.url) {
    return null;
  }

  let suffix = `?w=650&f=${format}`;
  if (width === 0) {
    suffix = `?f=${format}`;
  } else if (width > 0) {
    suffix = `?w=${width}&f=${format}`;
  }

  return `${product?.rollover_image?.url}${suffix}`;
};

export const getProductThumbnailImage = (product: Product, width?: number | null, format = 'a'): string => {
  if (!product || !product.thumbnail || !product.thumbnail.url) {
    return null;
  }

  let suffix = `?w=650&f=${format}`;
  if (width === 0) {
    suffix = `?f=${format}`;
  } else if (width > 0) {
    suffix = `?w=${width}&f=${format}`;
  }

  return product.thumbnail.url + suffix;
};

export const getFiltered = (products: Product[], _filters: ProductVariantFilters | any = {}): Product[] => {
  if (!products) {
    return [];
  }

  return products;
};

export const getAttributes = (products: Product, _filterByAttributeName?: string[]): Record<string, AgnosticAttribute | string> => {
  if (!products || !products?.configurable_options) {
    return {};
  }

  const attributes = {};
  const configurableOptions = products.configurable_options;

  // eslint-disable-next-line no-restricted-syntax
  for (const option of configurableOptions) {
    attributes[option.attribute_code] = {
      name: option.attribute_code,
      label: option.label,
      value: option.values.map((value) => {
        const obj = {};
        obj[value.uid] = value.label;
        return obj;
      }),
    } as AgnosticAttribute;
  }
  return attributes;
};

export const getSortedConfigurableOptions = (product: Product): Array<any> => {
  if (!product || !product?.configurable_options) {
    return [];
  }

  if (product?.configurable_product_options_selection?.configurable_options) {
    // Sort the values of each option only if numeric
    product.configurable_product_options_selection.configurable_options
      .sort((a, b) => (a.attribute_code > b.attribute_code ? 1 : (b.attribute_code > a.attribute_code ? -1 : 0)))
      .map((option, index) => {
        const optionCopy = option;
        if (index === 0) {
          // re-populate the colors with all available for the product, ignoring stock
          const allColors = product.configurable_options.find((obj) => obj.attribute_code === optionCopy.attribute_code);
          if (allColors !== undefined) {
            optionCopy.values = allColors.values.map((color) => {
              const newColorComposed: ConfigurableProductOptionValue = {
                uid: color.uid, label: color.label, is_available: false, swatch_data: color.swatch_data, is_use_default: false,
              };
              return newColorComposed;
            });
          }
        }
        return optionCopy;
      })
      .forEach((option) => {
        // If stringed values contains numbers, sort them numerically
        if (option.values.some((value) => !Number.isNaN(Number.parseFloat(value.label)))) {
          option.values.sort((a, b) => Number.parseFloat(a.label) - Number.parseFloat(b.label));
        }
      });

    return product.configurable_product_options_selection.configurable_options;
  }

  // Sort the values of each option only if numeric
  product.configurable_options
    .sort((a, b) => (a.attribute_code > b.attribute_code ? 1 : (b.attribute_code > a.attribute_code ? -1 : 0)))
    .forEach((option) => {
      // If stringed values contains numbers, sort them numerically
      if (option.values.some((value) => !Number.isNaN(Number.parseFloat(value.label)))) {
        option.values.sort((a, b) => Number.parseFloat(a.label) - Number.parseFloat(b.label));
      }
    });

  return product.configurable_options;
};

export const getDescription = (product: Product): string => {
  if (!product || !product?.description) {
    return '';
  }

  return product.description.html;
};

export const getShortDescription = (product: Product): string => {
  if (!product || !product.short_description) {
    return '';
  }
  return product.short_description.html;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCategoryIds = (product: Product): string[] => {
  const categoryIds = [];

  if (!product?.categories) {
    return categoryIds;
  }

  return product.categories.map((category) => category.uid);
};

export const getCategory = (product: any, currentUrlPath: string): Category | null => {
  if (!product?.categories || product?.categories.length === 0) {
    return null;
  }

  const categories = currentUrlPath.split('/');
  categories.pop();

  if (categories.length === 0) {
    return null;
  }

  const categoryPath = categories.join('/');

  // eslint-disable-next-line no-restricted-syntax
  for (const category of product.categories) {
    if (`/${category.url_path}` === categoryPath) {
      return category;
    }
  }

  return null;
};

export const getCategoryNameListingPage = (product: any, currentUrlPath: string): string | null => {
  if (!product?.categories || product?.categories.length === 0) {
    return '';
  }

  const categories = currentUrlPath.split('/');

  if (categories.length === 0) {
    return '';
  }
  const categoryPath = categories[categories.length - 1];

  // eslint-disable-next-line no-restricted-syntax
  for (const category of product.categories) {
    if (`/${category.url_path}` === `/${categoryPath}`) {
      return category.name;
    }
  }

  return '';
};

export const getCategoryIdListingPage = (product: any, currentUrlPath: string): string | null => {
  if (!product?.categories || product?.categories.length === 0) {
    return '';
  }

  const categories = currentUrlPath.split('/');

  if (categories.length === 0) {
    return '';
  }
  const categoryPath = categories[categories.length - 1];

  // eslint-disable-next-line no-restricted-syntax
  for (const category of product.categories) {
    if (`/${category.url_path}` === `/${categoryPath}`) {
      return category.uid;
    }
  }

  return '';
};

export const getCategoryNameProductPage = (product: any, category?: Category): string | null => {
  let categoryName = '';

  if (!product) {
    return categoryName;
  }

  if (category) {
    const categoryObj = categoryGetters.getBreadcrumbs(category);
    categoryName = categoryObj[categoryObj.length - 1].text;
  }

  return categoryName;
};

export const getId = (product: Product): string => product.uid;

export const getProductSku = (product: Product): string => product.sku;

export const getProductMerchantSku = (product: Product): string => product.merchant_sku;

// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
export const getTypeId = (product: Product): string => product.__typename;

export const getFormattedPrice = (price: number) => {
  if (price === null) {
    return null;
  }

  // TODO get correct data from api
  const locale = 'en';
  const country = 'en';
  const currency = 'USD';

  return new Intl.NumberFormat(`${locale}-${country}`, {
    style: 'currency',
    currency,
  }).format(price);
};

export const getBreadcrumbs = (product: any, category?: Category): AgnosticBreadcrumb[] => {
  let breadcrumbs = [];

  if (!product) {
    return breadcrumbs;
  }

  if (category) {
    breadcrumbs = categoryGetters.getBreadcrumbs(category) as AgnosticBreadcrumb[];
  }

  breadcrumbs.push({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    text: getName(product),
    route: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      path: getSlug(product),
    },
  });

  return breadcrumbs;
};

export const getProductRelatedProduct = (product: any): Product[] => product?.related_products || [];

export const getProductUpsellProduct = (product: any): Product[] => product?.upsell_products || [];

// export const getSwatchData = (swatchData: Product['configurable_options'][0]['values'][0]['swatch_data']): string | undefined => {
export const getSwatchData = (swatchData: SwatchData): SwatchData | undefined => {
  if (!swatchData) {
    return undefined;
  }
  return swatchData;
};

export const getSwatchDataHexCode = (swatchData: SwatchData): string | undefined => {
  if (!swatchData) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const sd = getSwatchData(swatchData);
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  switch (sd.__typename) {
    case 'ColorSwatchData':
    case 'AggregationOptionColorSwatchData':
      // @ts-ignore
      return sd.hex_code;
    case 'TextSwatchData':
    case 'AggregationOptionTextSwatchData':
      // @ts-ignore
      return sd.text;
    default:
      return undefined;
  }
};

export const getSwatchDataThumbnail = (swatchData: SwatchData): string | undefined => {
  if (!swatchData) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const sd = getSwatchData(swatchData);
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  switch (sd.__typename) {
    case 'ImageSwatchData':
    case 'AggregationOptionImageSwatchData':
      // @ts-ignore
      return sd.thumbnail.url;
    default:
      return undefined;
  }
};

// @ts-ignore
export const getListVariantSku = (product: Product): [string] | Array => product?.list_sku_variants || [];

export interface ProductGetters extends ProductGettersBase<Product, ProductVariantFilters> {
  getCategory(product: Product, currentUrlPath: string): Category | null;
  getCategoryNameListingPage(product: Product, currentUrlPath: string): string | null;
  getCategoryIdListingPage(product: Product, currentUrlPath: string): string | null;
  getCategoryNameProductPage(product: any, category?: Category): string | null;
  getProductRelatedProduct(product: Product): Product[];
  getProductSku(product: Product): string;
  getProductThumbnailImage(product: Product, width?: number | null, format?: string): string;
  getRolloverImage(product: Product, width?: number | null): string;
  getProductUpsellProduct(product: Product): Product[];
  getShortDescription(product: Product): string;
  getSlug(product: Product, category?: Category): string;
  getTypeId(product: Product): string;
  getSwatchData(swatchData: SwatchData): SwatchData | undefined;
  getSwatchDataHexCode(swatchData: SwatchData): string | undefined;
  getSwatchDataThumbnail(swatchData: SwatchData): string | undefined;
  getListVariantSku(product: Product): [string] | [];
}

const productGetters: ProductGetters = {
  getAverageRating(_product: Product): number {
    return 0;
  },
  getTotalReviews(_product: Product): number {
    return 0;
  },
  getAttributes,
  getBreadcrumbs,
  getCategory,
  getCategoryNameListingPage,
  getCategoryIdListingPage,
  getCategoryNameProductPage,
  getCategoryIds,
  getCoverImage,
  getDescription,
  getFiltered,
  getFormattedPrice,
  getGallery,
  getId,
  getEntityId,
  getName,
  getPrice,
  getFinalPrice,
  getProductRelatedProduct,
  getProductSku,
  getProductMerchantSku,
  getProductThumbnailImage,
  getRolloverImage,
  getProductUpsellProduct,
  getShortDescription,
  getSlug,
  getTypeId,
  getSwatchData,
  getSwatchDataHexCode,
  getSwatchDataThumbnail,
  getListVariantSku,
  getSortedConfigurableOptions,
  getProductImageAlt,
};

export default productGetters;
