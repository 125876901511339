/* eslint-disable no-param-reassign, consistent-return */
import { Context, Logger } from '@vue-storefront/core';
import { CustomerAddress, CustomerAddressInput } from '@vue-storefront/gemini-api';
import { transformUserCreateAddressInput, transformUserUpdateAddressInput } from '../../helpers/userAddressManipulator';
import { useAddressesFactory, UseAddressesParams } from '../../factories/useAddressesFactory';
import { CustomQueryParams } from '../../types/composables';

type SaveAddressInput = {
  address: CustomerAddressInput;
} & CustomQueryParams;

type UpdateAddressInput = {
  address: CustomerAddressInput;
} & CustomQueryParams;

type RemoveAddressInput = {
  address: {
    id: string;
  };
} & CustomQueryParams;

const factoryParams: UseAddressesParams<CustomerAddress, CustomQueryParams, SaveAddressInput, UpdateAddressInput, RemoveAddressInput> = {
  load: async (context: Context, _params?: CustomQueryParams) => {
    Logger.debug('[Gemini] load user addresses');

    const { data } = await context.$gemini.api.getCustomerAddresses();

    return data.customer.addresses ?? [];
  },
  save: async (context: Context, saveParams) => {
    Logger.debug('[Gemini] save user address:', saveParams.address);
    const { data, errors } = await context.$gemini.api.createCustomerAddress(transformUserCreateAddressInput(saveParams));

    if (errors) {
      throw new Error(errors[0].message);
    }
    Logger.debug('[Result]:', { data });

    return data.createCustomerAddress ?? { id: null };
  },
  remove: async (context: Context, params) => {
    Logger.debug('[Gemini] remove user addresses');

    const { data } = await context.$gemini.api.deleteCustomerAddress(params.address.id);

    Logger.debug('[Result]:', { data });

    return !!data.deleteCustomerAddress;
  },
  update: async (context: Context, params) => {
    Logger.debug('[Gemini] update user addresses', params);

    const { data } = await context.$gemini.api.updateCustomerAddress(transformUserUpdateAddressInput(params));

    Logger.debug('[Result]:', { data });

    return data?.updateCustomerAddress ?? { id: null };
  },
};

export default useAddressesFactory(factoryParams);
