/* istanbul ignore file */
import {
  WishlistGetters as BaseWishlistGetters,
  AgnosticPrice, AgnosticTotals,
} from '@vue-storefront/core';
import {
  Wishlist, WishlistItem,
} from '@vue-storefront/gemini-api';
import { getPrice, getProductThumbnailImage } from './productGetters';

export type WishlistProduct = WishlistItem;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getItems = (wishlist): WishlistItem[] => wishlist?.items?.items ?? [];

export const getItemName = (wishlistItem: WishlistItem): string => wishlistItem?.product?.name || '';

export const getItemImage = (wishlistItem: WishlistItem): string => getProductThumbnailImage(wishlistItem?.product) || '';

export const getItemPrice = (wishlistItem: WishlistItem): AgnosticPrice => ({
  regular: getPrice(wishlistItem?.product).regular,
  special: getPrice(wishlistItem?.product).special,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getItemQty = (wishlistItem: WishlistItem): number => wishlistItem.qty;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getItemAttributes = (wishlistItem: WishlistItem, filterByAttributeName?: string[]) => ({ '': '' });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getItemSku = (wishlistItem: WishlistItem): string => wishlistItem?.product?.merchant_sku || '';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getTotals = (wishlist): AgnosticTotals => (wishlist?.items?.items.reduce((acc, curr) => ({
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-argument
  total: acc.total + getItemPrice(curr).special,
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-argument
  subtotal: acc.subtotal + getItemPrice(curr).regular,
}), ({ total: 0, subtotal: 0 })));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getShippingPrice = (wishlist: Wishlist): number => 0;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getTotalItems = (wishlist: Wishlist): number => (Array.isArray(wishlist) ? wishlist[0]?.items_count : (wishlist?.items_count || 0));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getFormattedPrice = (price: number): string => '';

// const getPagination = (wishlistData: Wishlist): AgnosticPagination => ({
//   currentPage: wishlistData?.items_v2?.page_info?.current_page || 1,
//   totalPages: wishlistData?.items_v2?.page_info?.total_pages || 1,
//   totalItems: wishlistData?.items_count || 0,
//   itemsPerPage: wishlistData?.items_v2?.page_info?.page_size || 10,
//   pageOptions: [10, 50, 100],
// });

export interface WishlistGetters extends BaseWishlistGetters<Wishlist, WishlistItem> {
  getShippingPrice(wishlist: Wishlist): number;

  getItemQty(product: WishlistItem): number;

  // getPagination(wishlistData): AgnosticPagination;
}

const wishlistGetters: WishlistGetters = {
  getTotals,
  getShippingPrice,
  getItems,
  getItemName,
  getItemImage,
  getItemPrice,
  getItemQty,
  getItemAttributes,
  getItemSku,
  getTotalItems,
  getFormattedPrice,
  // getPagination,
};

export default wishlistGetters;
