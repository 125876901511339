import {
  Context,
  Logger,
  useUserShippingFactory,
  UseUserShippingFactoryParams,
} from '@vue-storefront/core';
import useUser from '../useUser';
import { transformUserCreateAddressInput, transformUserUpdateAddressInput } from '../../helpers/userAddressManipulator';

const factoryParams: UseUserShippingFactoryParams<any, any> = {
  provide() {
    return {
      user: useUser(),
    };
  },

  addAddress: async (context: Context, params?) => {
    Logger.debug('[Gemini]: add shipping address', { params });

    const { data } = await context.$gemini.api.createCustomerAddress(
      transformUserCreateAddressInput(params),
    );

    Logger.debug('[Result]:', { data });

    return data?.createCustomerAddress ?? {};
  },

  deleteAddress: async (context: Context, params) => {
    Logger.debug('[Gemini] delete shipping address', { params });
    // eslint-disable-next-line
    const { data } = await context.$gemini.api.deleteCustomerAddress(params.address.id);

    return data?.deleteCustomerAddress ?? {};
  },

  updateAddress: async (context: Context, params) => {
    Logger.debug('[Gemini] update shipping address', { params });

    const { data } = await context.$gemini.api.updateCustomerAddress(transformUserUpdateAddressInput(params));

    return data?.updateCustomerAddress ?? {};
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  load: async (context: Context, _params?) => {
    Logger.debug('[Gemini] load user address');

    if (!context.user.user?.value?.id) {
      await context.user.load();
    }

    return context.user.user?.value;
  },

  setDefaultAddress: async (context: Context, params) => {
    Logger.debug('[Gemini] set default shipping address');
    if (!context.user.user?.value?.id) {
      await context.user.load();
    }

    const { data } = await context.$gemini.api.updateCustomerAddress(transformUserUpdateAddressInput({
      ...params,
      default_shipping: true,
    }));

    Logger.debug('[Result]:', { data });

    if (data?.updateCustomerAddress && context.user.user) {
      return { ...data.updateCustomerAddress, addresses: context.user.user.value.addresses };
    }

    return data?.updateCustomerAddress ?? {};
  },
};

export default useUserShippingFactory<any, any>(factoryParams);
