import { Ref, computed } from '@vue/composition-api';
import {
  configureFactoryParams, Context, CustomQuery, FactoryParams, Logger, sharedRef,
} from '@vue-storefront/core';
import { ComposableFunctionArgs, PlatformApi } from '@vue-storefront/core/lib/src/types';
import { UseWishlist, UseWishlistErrors } from '../types/composables';

export interface UseWishlistFactoryParams<WISHLIST, PRODUCT, API extends PlatformApi = any> extends FactoryParams<API> {
  load: (
    context: Context,
    params: ComposableFunctionArgs<{
      nextPageToken?: string;
      pageSize?: number;
    }>
  ) => Promise<WISHLIST>;
  addItem: (
    context: Context,
    params: ComposableFunctionArgs<{
      product: PRODUCT;
      quantity: number;
    }>
  ) => Promise<WISHLIST>;
  removeItem: (
    context: Context,
    params: ComposableFunctionArgs<{
      product: PRODUCT;
    }>
  ) => Promise<WISHLIST>;
  updateItem: (
    context: Context,
    params: ComposableFunctionArgs<{
      product: PRODUCT;
      quantity: number;
    }>
  ) => Promise<WISHLIST>;
  clear: (context: Context, params: { currentWishlist: WISHLIST }) => Promise<WISHLIST>;
  hasItemsInWishlist: (context: Context, params: { items: Array<string> }) => Promise<Record<string, boolean>>;
  getProductWishlistMap: (context: Context, params: { product: PRODUCT }) => Promise<Record<string, string>> | null;
  isInWishlist: (context: Context, params: { currentWishlist: WISHLIST; product: PRODUCT }) => boolean;
}

export const useWishlistFactory = <WISHLIST, PRODUCT, API extends PlatformApi = any>(factoryParams: UseWishlistFactoryParams<WISHLIST, PRODUCT, API>) => (ssrKey = 'useWishlistFactory'): UseWishlist<WISHLIST, PRODUCT, API> => {
  const loading: Ref<boolean> = sharedRef<boolean>(false, `useWishlist-loading-${ssrKey}`);
  const wishlist: Ref<WISHLIST> = sharedRef(null, `useWishlist-wishlist-${ssrKey}`);
  const error: Ref<UseWishlistErrors> = sharedRef(
    {
      addItem: null,
      removeItem: null,
      load: null,
      clear: null,
    },
    `useWishlist-error-${ssrKey}`,
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
  const _factoryParams = configureFactoryParams(factoryParams, {
    mainRef: wishlist,
    alias: 'currentWishlist',
    loading,
    error,
  });

  const addItem = async ({ product, quantity, customQuery }) => {
    Logger.debug(`useWishlist/${ssrKey}/addItem`, product, quantity);

    try {
      loading.value = true;
      const updatedWishlist = await _factoryParams.addItem({
        product,
        quantity,
        customQuery,
      });
      error.value.addItem = null;
      wishlist.value = updatedWishlist;
    } catch (err) {
      error.value.addItem = err;
      Logger.error(`useWishlist/${ssrKey}/addItem`, err);
    } finally {
      loading.value = false;
    }
  };

  const updateItem = async ({ product, quantity, customQuery }) => {
    Logger.debug(`useWishlist/${ssrKey}/updateItem`, product, quantity);

    try {
      loading.value = true;
      const updatedWishlist = await _factoryParams.updateItem({
        product,
        quantity,
        customQuery,
      });
      error.value.updateItem = null;
      wishlist.value = updatedWishlist;
    } catch (err) {
      error.value.updateItem = err;
      Logger.error(`useWishlist/${ssrKey}/updateItem`, err);
    } finally {
      loading.value = false;
    }
  };

  const removeItem = async ({ product, customQuery }) => {
    Logger.debug(`useWishlist/${ssrKey}/removeItem`, product);

    try {
      loading.value = true;
      const updatedWishlist = await _factoryParams.removeItem({
        product,
        customQuery,
      });
      error.value.removeItem = null;
      wishlist.value = updatedWishlist;
    } catch (err) {
      error.value.removeItem = err;
      Logger.error(`useWishlist/${ssrKey}/removeItem`, err);
    } finally {
      loading.value = false;
    }
  };

  const load = async (params: {
    nextPageToken?: string;
    pageSize?: number;
    customQuery?: CustomQuery;
  }) => {
    Logger.debug(`useWishlist/${ssrKey}/load`);
    try {
      loading.value = true;
      wishlist.value = await _factoryParams.load(params);
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      wishlist.value = null;
      Logger.error(`useWishlist/${ssrKey}/load`, err);
    } finally {
      loading.value = false;
    }
  };

  const clear = async () => {
    Logger.debug(`useWishlist/${ssrKey}/clear`);

    try {
      loading.value = true;
      const updatedWishlist = await _factoryParams.clear();
      error.value.clear = null;
      wishlist.value = updatedWishlist;
    } catch (err) {
      error.value.clear = err;
      Logger.error(`useWishlist/${ssrKey}/clear`, err);
    } finally {
      loading.value = false;
    }
  };

  const isInWishlist = ({ product }) => {
    Logger.debug(`useWishlist/${ssrKey}/isInWishlist`, product);

    return _factoryParams.isInWishlist({
      product,
    });
  };

  const hasItemsInWishlist = ({ items }) => {
    Logger.debug(`useWishlist/${ssrKey}/hasItemsInWishlist`, items);

    return _factoryParams.hasItemsInWishlist({
      items,
    });
  };

  const getProductWishlistMap = ({ product }) => {
    Logger.debug(`useWishlist/${ssrKey}/getProductWishlistMap`, product);

    return _factoryParams.getProductWishlistMap({
      product,
    });
  };

  return {
    api: _factoryParams.api,
    wishlist: computed(() => wishlist.value),
    getProductWishlistMap,
    isInWishlist,
    addItem,
    load,
    removeItem,
    clear,
    hasItemsInWishlist,
    updateItem,
    loading: computed(() => loading.value),
    error: computed(() => error.value),
  };
};
